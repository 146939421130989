export interface GradientShadowProps {
  gradientBackground: string;
  zIndex?: number;
}

const GradientShadow = ({
  gradientBackground,
  zIndex = 1,
}: GradientShadowProps) => {
  return (
    <div
      className="pointer-events-none absolute size-full"
      style={{
        background: gradientBackground,
        zIndex: zIndex,
      }}
    />
  );
};

export default GradientShadow;
