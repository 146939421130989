import clsx from 'clsx';
import { Control, useController } from 'react-hook-form';

import { FormSchemaContactType } from '@/constants/formSchemas';

type Props = {
  label: string;
  placeholder: string;
  type: string;
  name: 'fullName' | 'companyName' | 'email';
  className?: string;
  classNameWrapper?: string;
  control: Control<FormSchemaContactType>;
};

export const Input = ({
  label,
  placeholder,
  type,
  name,
  className,
  classNameWrapper,
  control,
}: Props) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <div className={clsx('relative w-full px-4', classNameWrapper)}>
      <div className="mb-7 lg:mb-9">
        <label className="mb-3 block text-h6 font-medium text-collapo-black">
          <span>{label}:</span>
        </label>
        <div className={clsx('relative w-full', className)}>
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={clsx(
              'peer block w-full appearance-none border bg-collapo-white px-[14px] py-3 text-h6 leading-relaxed outline-none transition-colors duration-300 focus:outline-none focus-visible:shadow-none motion-reduce:transition-none md:px-[18px] md:py-4',
              value ? 'text-collapo-black' : 'text-collapo-black-40',
              error
                ? 'border-collapo-red'
                : 'border-collapo-black-40 focus:border-collapo-blue',
            )}
            id={name}
          />
          {error && (
            <p className="absolute left-0 top-full pt-2 text-p-small text-collapo-red">
              {error?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
