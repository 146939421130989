import { useRef } from 'react';

import { CrossIcon } from '@/components/icons/Arrows';
import { SuccessIcon } from '@/components/icons/Success';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useCloseByEsc } from '@/hooks/useCloseByEsc';

type Props = {
  onClick: () => void;
};

export const SuccessModal = ({ onClick }: Props) => {
  const ref = useRef(null);

  useClickOutside(ref, onClick);
  useCloseByEsc({ handleClose: onClick });

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-collapo-black/80 px-4">
      <div
        ref={ref}
        className="relative flex h-fit max-h-[calc(100svh-60px)] min-h-[352px] w-full max-w-[488px] flex-col items-center justify-center overflow-y-auto bg-collapo-black-10 p-9 backdrop-blur-3xl"
      >
        <button
          className="absolute right-4 top-4"
          onClick={onClick}
          aria-label="Close"
        >
          <CrossIcon />
        </button>
        <div className="flex flex-wrap justify-center gap-y-8">
          <SuccessIcon />
          <div className="w-full space-y-4">
            <h3 className="w-full text-center text-h3 text-collapo-black">
              Zpráva odeslána
            </h3>
            <p className="w-full text-center text-p text-collapo-black">
              Děkujeme, brzy se vám ozveme.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
