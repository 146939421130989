import {
  FooterQuery,
  FormContactWebexQuery,
  HeroImageSectionFragment,
  HeroVideoSectionFragment,
  HomeContentFragment,
  NavigationQuery,
  SeoFragment,
} from '@/api';
import { FormContact } from '@/components/form/contactForm/FormContact';
import { Layout } from '@/components/layout/Layout';
import { Seo } from '@/components/Seo';
import DynamicContent from '@/components/shared/DynamicContent';
import HeroImageSection from '@/components/shared/HeroImageSection';
import HeroVideoSection from '@/components/shared/HeroVideoSection';

type Props = {
  seo?: SeoFragment | null;
  heroVideo?: HeroVideoSectionFragment | null;
  heroImage?: HeroImageSectionFragment | null;
  content?: HomeContentFragment | null;
  formWebexData: FormContactWebexQuery;
  footerData: FooterQuery;
  headerData: NavigationQuery;
};

export const Homepage = ({
  seo,
  heroVideo,
  heroImage,
  content,
  formWebexData,
  footerData,
  headerData,
}: Props) => {
  return (
    <Layout footerData={footerData} headerData={headerData}>
      <Seo seo={seo} />
      {heroVideo && <HeroVideoSection hero={heroVideo} />}
      {heroImage && <HeroImageSection hero={heroImage} />}
      {content?.content && content?.content?.length > 0 && (
        <DynamicContent content={content} />
      )}
      {formWebexData && <FormContact data={formWebexData} />}
    </Layout>
  );
};
