import { HeroVideoSectionFragment } from '@/api';
import HeroSection, {
  HeroSectionProps,
} from '@/components/HeroSection/HeroSection';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface HeroVideoSectionProps {
  hero?: HeroVideoSectionFragment | null;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

export default function HeroVideoSection({
  hero,
  titleMaxWidth,
  subtitleMaxWidth,
}: HeroVideoSectionProps) {
  const heroProps: HeroSectionProps = {
    enabledLazyLoading: true,
    titleMaxWidth,
    subtitleMaxWidth,
    title: hero?.title || '',
    subtitle: hero?.subtitle || '',
    button: hero?.button?.label
      ? {
          path: hero?.button?.path || '',
          label: hero?.button?.label || '',
        }
      : undefined,
    heroVideo: {
      enabledLazyLoading: true,
      pauseVideo: undefined,
      video: {
        posterAlt: hero?.heroVideo?.poster_alt || '',
        posterSrc: getCdlPublicId(
          hero?.heroVideo?.poster?.data?.attributes?.url || '',
        ),
        videoSrc: getCdlPublicId(
          hero?.heroVideo?.video?.data?.attributes?.url || '',
        ),
        blurHash:
          hero?.heroVideo?.poster?.data?.attributes?.blurhash ||
          'LAS?DV%M_3D%t7fQofj[~qM{4n-;',
      },
    },
  };

  return <HeroSection {...heroProps} />;
}
