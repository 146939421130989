import { Fragment } from 'react';

import PictureWithBlurHash from '@/components/PictureWithBlurHash/PictureWithBlurHash';
import { imageType } from '@/types/imageType';

export interface HeroImageProps {
  enabledLazyLoading?: boolean;
  image?: imageType;
}
const HeroImage = ({ enabledLazyLoading, image }: HeroImageProps) => {
  return (
    <Fragment>
      {image && (
        <PictureWithBlurHash
          imgSrc={image?.imgSrc}
          imgSrcMobile={image?.imgSrcMobile}
          isPathMobile={!!image?.imgSrcMobile}
          alt={image?.alt}
          fullScreen
          enabledLazyLoading={enabledLazyLoading}
          classNameWrapper="z-0 max-h-[1400px] min-h-[560px] w-full h-[calc(100svh-80px)] md:h-[calc(100svh-155px)]"
          blurHash={image?.blurHash}
        />
      )}
    </Fragment>
  );
};

export default HeroImage;
