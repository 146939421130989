import { HeroImageSectionFragment } from '@/api';
import HeroSection, {
  HeroSectionProps,
} from '@/components/HeroSection/HeroSection';
import getCdlPublicId from '@/hooks/useCdlPublicId';

interface HeroVideoSectionProps {
  hero?: HeroImageSectionFragment | null;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
}

export default function HeroImageSection({
  hero,
  titleMaxWidth,
  subtitleMaxWidth,
}: HeroVideoSectionProps) {
  const heroProps: HeroSectionProps = {
    enabledLazyLoading: true,
    titleMaxWidth,
    subtitleMaxWidth,
    title: hero?.title || '',
    subtitle: hero?.subtitle || '',
    button: hero?.button
      ? {
          label: hero?.button?.label || '',
          path: hero?.button?.path || '',
          openInNewTab: !!hero?.button?.openInNewTab,
        }
      : undefined,
    heroImage: {
      image: {
        imgSrc: getCdlPublicId(
          hero?.heroImage?.img_desktop?.data?.attributes?.url || '',
        ),
        imgSrcMobile: getCdlPublicId(
          hero?.heroImage?.img_mobile?.data?.attributes?.url || '',
        ),
        alt: hero?.heroImage?.alt || '',
        blurHash:
          hero?.heroImage?.img_desktop?.data?.attributes?.blurhash ||
          '12312312',
      },
    },
  };

  return <HeroSection {...heroProps} />;
}
