import { Fragment } from 'react';
import sanitizeHtml from 'sanitize-html';

import ButtonLink from '@/components/ButtonLink/ButtonLink';
import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';
import HeroImage, { HeroImageProps } from '@/components/HeroImage/HeroImage';
import HeroVideo, { HeroVideoProps } from '@/components/HeroVideo/HeroVideo';
import GradientShadow from '@/components/shared/GradientShadow/GradientShadow';
import { HERO_GRADIENT_BG } from '@/constants/gradientShadows';
import { ButtonLinkType } from '@/types/buttonType';

export interface HeroSectionProps {
  heroImage?: HeroImageProps;
  heroVideo?: HeroVideoProps;
  title: string;
  enabledLazyLoading?: boolean;
  subtitle?: string;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
  button?: ButtonLinkType;
}

const HeroSection = ({
  heroImage,
  heroVideo,
  title,
  subtitle,
  button,
  enabledLazyLoading = false,
  titleMaxWidth,
  subtitleMaxWidth,
}: HeroSectionProps) => {
  const sanitizedTitleValue = sanitizeHtml(title);

  return (
    <GlobalWrapperSection
      classNameInner="relative justify-center"
      maxDesktopWidth={false}
      enabledGlobalPaddings={false}
    >
      <Fragment>
        <GradientShadow gradientBackground={HERO_GRADIENT_BG} />
        {heroImage?.image?.imgSrc && !heroVideo?.video?.videoSrc ? (
          <HeroImage
            image={heroImage.image}
            enabledLazyLoading={enabledLazyLoading}
          />
        ) : null}
        {heroVideo?.video?.videoSrc ? (
          <HeroVideo
            video={heroVideo.video}
            enabledLazyLoading={enabledLazyLoading}
          />
        ) : null}
        <div className="absolute bottom-0 left-1/2 z-[2] flex w-full max-w-desktop -translate-x-1/2 flex-wrap items-end justify-between pt-10 lg:px-4 lg:pb-10 xl:px-10">
          <div className="flex max-w-[900px] flex-wrap gap-y-6 max-lg:px-4 max-lg:pb-[30px] lg:w-[calc(100%-326px-40px)] lg:gap-y-10">
            {(title || subtitle) && (
              <div className="w-full space-y-6 lg:space-y-7">
                <Fragment>
                  {title && (
                    <div>
                      <Fragment>
                        <h1
                          style={{ maxWidth: titleMaxWidth }}
                          className="w-full text-h1-mobile text-collapo-white lg:text-h1"
                          dangerouslySetInnerHTML={{
                            __html: sanitizedTitleValue,
                          }}
                        />
                      </Fragment>
                    </div>
                  )}
                  {subtitle && (
                    <div>
                      <Fragment>
                        <h2
                          style={{ maxWidth: subtitleMaxWidth }}
                          className="w-full text-p text-collapo-white"
                        >
                          {subtitle}
                        </h2>
                      </Fragment>
                    </div>
                  )}
                </Fragment>
              </div>
            )}
            {button && (
              <div className="w-full sm:w-fit sm:max-w-max">
                <ButtonLink
                  href={button.path}
                  ariaLabel={button.label}
                  openInNewTab={button.openInNewTab}
                >
                  {button.label}
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    </GlobalWrapperSection>
  );
};

export default HeroSection;
