import { Fragment } from 'react';

import Video, { VideoProps } from '@/components/Video/Video';

export interface HeroVideoProps {
  enabledLazyLoading?: boolean;
  pauseVideo?: boolean;
  video?: VideoProps;
}
const HeroVideo = ({
  enabledLazyLoading,
  video,
  pauseVideo,
}: HeroVideoProps) => {
  return (
    <Fragment>
      {video && (
        <Video
          key={`heroVideo-video-1`}
          pauseVideo={pauseVideo}
          videoSrc={video.videoSrc}
          posterSrc={video.posterSrc}
          posterAlt={video.posterAlt}
          blurHash={video.blurHash}
          width={video.width}
          height={video.height}
          enabledLazyLoading={enabledLazyLoading}
          className="z-0 h-[calc(100svh-80px)] max-h-[1400px] min-h-[560px] md:h-[calc(100svh-155px)]"
          parentSection="heroVideo"
          parentIndex={1}
        />
      )}
    </Fragment>
  );
};

export default HeroVideo;
