import clsx from 'clsx';

type Props = {
  className?: string;
  disabled?: boolean;
  hasLighterColors?: boolean;
  invertedColor?: boolean;
};

export const CrossIcon = ({ className }: Props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'size-8 stroke-black transition-colors duration-300 hover:stroke-collapo-blue group-hover:stroke-collapo-blue motion-reduce:transition-none',
      className,
    )}
  >
    <path
      d="M6.39929 6.46875L24.9642 25.0336"
      stroke="black"
      strokeWidth="1.8"
      strokeLinejoin="round"
    />
    <path
      d="M6.39929 24.9648L24.9642 6.39998"
      stroke="black"
      strokeWidth="1.8"
      strokeLinejoin="round"
    />
  </svg>
);
