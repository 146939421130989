/* eslint-disable no-console */

import { Fragment, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';

import {
  ContactFormDataInput,
  FormContactWebexQuery,
  useSendContactFormMutation,
} from '@/api';
import {
  FormSectionContact,
  FormSectionProps,
} from '@/components/form/contactForm/FormSectionContact';
import { FormAddress } from '@/components/form/FormAddress';
import GlobalWrapperSection from '@/components/GlobalWrapperSection/GlobalWrapperSection';

export const FormContact = ({ data }: { data: FormContactWebexQuery }) => {
  const [success, setSuccess] = useState(false);
  const [sendForm] = useSendContactFormMutation({
    onCompleted() {
      setSuccess(true);
    },
  });

  const onSubmit: SubmitHandler<ContactFormDataInput> = async (formData) => {
    try {
      await sendForm({ variables: { data: formData } });
    } catch (error) {
    } finally {
    }
  };

  const formData = data?.webexIntegration?.data?.attributes;

  const formProps: FormSectionProps = {
    anchor: formData?.slug || undefined,
    onSubmit: onSubmit,
    success: success,
    setSuccess: () => setSuccess(!success),
  };

  return (
    <>
      <GlobalWrapperSection
        classNameWrapper="bg-collapo-white"
        classNameInner="flex-wrap justify-center xl:px-10"
        enabledDesktopPaddingX={false}
      >
        <FormAddress />
      </GlobalWrapperSection>
      <GlobalWrapperSection
        classNameWrapper="bg-collapo-black-10"
        classNameInner="flex-wrap justify-center xl:px-10"
        enabledDesktopPaddingX={false}
        anchor="kontaktujte-nas"
      >
        <div className="w-full">
          <div className="flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-15 max-w-screen-lg text-center lg:mb-20">
                <span className="mb-2 block text-h6 font-semi-bold uppercase text-collapo-blue">
                  Kontaktujte nás
                </span>
                <h2 className="mb-10 mt-8 text-h2 font-bold text-collapo-black sm:text-h2-mobile md:text-[40px]">
                  Neváhejte, vyzkoušejte!
                </h2>
                <div className="flex flex-wrap justify-center gap-y-6">
                  <p className="text-p text-collapo-black">
                    Přesvědčte se sami, jak umíme zlepšit i vaši spolupráci.
                    Nejlepším důkazem je osobní zkušenost. Naše řešení si můžete
                    vyzkoušet kdykoliv a z pohodlí své kanceláře.
                  </p>
                  <p className="text-p text-collapo-black">
                    Vedle základních konceptů (v zasedačce, kdekoliv a kdykoliv)
                    pro vás rádi připravíme i další testovací nasazení na míru.
                  </p>
                  <p className="text-p text-collapo-black">
                    Dejte nám o sobě vědět a naši specialisté vás budou obratem
                    kontaktovat.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <FormSectionContact {...formProps} />
          </div>
        </div>
      </GlobalWrapperSection>
    </>
  );
};
